<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">VOUCHER MAINTENANCE</h4></v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12>
          <v-form ref="form" class="multi-col-validation">
            <v-layout row wrap class="mx-1 mb-5">
              <v-flex xs12 md4>
                <v-select
                  v-model="month_of_id"
                  class="mx-2"
                  dense
                  outlined
                  label="Month Of"
                  :items="month_of_items"
                  item-value="id"
                  item-text="month_of"
                  @change="selected_type_of_voucher"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-flex>
              <v-flex xs12 md4>
                <v-select
                  v-model="voucher_type"
                  class="mx-2"
                  dense
                  outlined
                  label="Voucher Type"
                  :items="['Cash Voucher', 'Cheque Voucher', 'Fund Transfer Voucher']"
                  @change="selected_type_of_voucher"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-flex>
              <v-flex xs12 md4 class="mb-10">
                <v-select
                  v-model="voucher_id"
                  class="mx-2"
                  dense
                  outlined
                  label="Voucher #"
                  :items="voucher_items"
                  item-value="id"
                  item-text="voucher_no"
                  @change="selected_voucher"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-flex>
              <v-flex xs12 md12 v-show="alert">
                <v-alert color="warning" text class="mb-0">
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-flex>
              <v-flex xs12 md3 v-if="can_disapproved">
                <v-btn class="w-full" color="primary" @click="disapprove_voucher"
                       v-if="!saving_data">
                  Dispproved Voucher
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                     v-else></v-progress-circular>
              </v-flex>
              <v-flex xs12 md3>
                <v-btn class="w-full" color="info" @click="edit_info_selected"
                       v-if="!saving_data">
                  Edit Info
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="info" indeterminate
                                     v-else></v-progress-circular>
              </v-flex>
              <v-flex xs12 md3>
                <v-btn class="w-full" color="error" @click="delete_voucher_selected"
                       v-if="!saving_data">
                  Delete Voucher
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="error" indeterminate
                                     v-else></v-progress-circular>
              </v-flex>
              <v-flex xs12 md3 v-if="voucher_type === 'Cash Voucher'">
                <v-btn class="w-full" color="warning" @click="cancel_cheque_selected"
                       v-if="!saving_data">
                  Cancel Cheque
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="warning" indeterminate
                                     v-else></v-progress-circular>
              </v-flex>
            </v-layout>
          </v-form>
          <v-flex xs12 md12 class="mx-2">
            <h2>Request Data | Total: P {{ total_amount }}</h2>
            <v-data-table dense :headers="headers" :items="data_items">
              <template v-slot:top>
                LEGENDS -
                (EDIT-
                <v-icon
                  class="mr-2"
                  color="info"
                >
                  {{icons.mdiPencil}}
                </v-icon>
                ),
                (CHANGE CHEQUE-
                <v-icon
                  class="mr-2"
                  color="error"
                >
                  {{icons.mdiCheckbook}}
                </v-icon>
                )
                (REMOVE REQUEST ON THE VOUCHER-
                <v-icon
                  class="mr-2"
                  color="error"
                >
                  {{icons.mdiDeleteForever}}
                </v-icon>
                )
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.request_id }}
                  </td>
                  <td>
                    {{ item.category.category }}
                  </td>
                  <td v-if="voucher_type != 'Fund Transfer Voucher'">
                    {{ item.branch != null ? item.branch.branch_code : '' }}
                  </td>
                  <td v-else>
                    {{
                    item.ftv_from_bank != null
                    ? item.ftv_from_bank.bank_code + '|' + item.ftv_from_bank.account_no
                    : ''
                    }}
                  </td>
                  <td v-if="voucher_type != 'Fund Transfer Voucher'">
                    {{ item.particulars != null ? item.particulars.particulars : '' }}
                  </td>
                  <td v-else>
                    {{
                    item.ftv_to_bank != null ? item.ftv_to_bank.bank_code + '|' +
                    item.ftv_from_bank.account_no : ''
                    }}
                  </td>
                  <td>
                    {{ item.description }}
                  </td>
                  <td v-if="voucher_type != 'Fund Transfer Voucher'">
                    {{ item.credit_to }}
                  </td>
                  <td v-else>
                    {{ item.cheque_no }}
                  </td>
                  <td v-if="voucher_type != 'Fund Transfer Voucher'">
                    {{ item.payment_for }}
                  </td>
                  <td v-else>
                    {{ item.cheque_date }}
                  </td>
                  <td v-if="voucher_type === 'Cheque Voucher'">
                    {{ item.cheque_no }}
                  </td>
                  <td v-if="voucher_type === 'Cheque Voucher'">
                    {{ item.cheque_date }}
                  </td>
                  <td>
                    {{
                    item.pdc_status === 0 && voucher_type != 'Cash Voucher'
                    ? 'Not Count:' + formatPrice(item.amount)
                    : formatPrice(item.amount)
                    }}
                  </td>
                  <td class="text-center">
                    <v-icon class="mr-2" color="info" @click="edit_request_data(item)">
                      {{ icons.mdiPencil }}
                    </v-icon>
                    <div v-if="item.pdc_status === 1 && item.cheque_no > 0">
                      <v-icon class="mr-2" color="error" @click="change_cheque(item)">
                        {{ icons.mdiCheckbook }}
                      </v-icon>
                    </div>
                    <div
                      v-if="voucher_type==='Cash Voucher'">
                      <v-icon class="mr-2" color="error" @click="remove_from_voucher(item)">
                        {{ icons.mdiDeleteForever }}
                      </v-icon>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
    <v-dialog v-model="EditInfo" persistent max-width="50%">
      <v-card>
        <v-card-text>
          <v-form ref="form2" class="multi-col-validation">
            <v-row>
              <v-col md="12" cols="12">
                <v-text-field
                  v-model="payee"
                  label="Payee"
                  dense
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field
                  v-model="voucher_date"
                  label="Date"
                  dense
                  type="date"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field
                  v-model="allocation_of_fund"
                  label="Allocation Of Fund"
                  dense
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-col cols="12" v-show="alert_customize">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message_customize }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
        </v-card-text>
      </v-card>
      <v-btn color="primary" @click="edit_info"> Save Changes</v-btn>
      <v-btn color="error" @click="EditInfo = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="AskCanceledCheque" persistent max-width="50%">
      <v-card>
        <v-card-text>
          <v-col md="12" cols="12">
            <v-radio-group v-model="is_cheque_ask">
              <v-radio label="Is Canceled Cheque?" value="1" v-if="is_cheque_ask != '3'"></v-radio>
              <v-radio label="Is Return Cheque?" value="0" v-if="is_cheque_ask != '3'"></v-radio>
              <v-radio label="Is Auto Debit?" value="3" disabled
                       v-if="is_cheque_ask === '3'"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" v-show="alert_customize">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message_customize }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col md="12" cols="12" v-if="is_cheque_ask === '1'">
            <v-text-field
              v-model="reason"
              label="Reason of Canceled Cheque"
              dense
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-btn color="primary" @click="delete_voucher(1)"> Save Changes</v-btn>
          </v-col>
          <v-col md="12" cols="12" v-else-if="is_cheque_ask === '0'">
            <v-btn color="primary" @click="delete_voucher(0)"> Save Changes</v-btn>
          </v-col>
          <v-col md="12" cols="12" v-else>
            <v-btn color="primary" @click="delete_voucher(3)"> Delete Voucher</v-btn>
          </v-col>
        </v-card-text>
      </v-card>
      <v-btn color="error" @click="AskCanceledCheque = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="AskCanceledChequeCash" persistent max-width="50%">
      <v-card>
        <v-card-text>
          <v-col md="12" cols="12">
            <v-radio-group v-model="is_cheque_ask">
              <v-radio label="Is Canceled Cheque?" value="1"></v-radio>
              <v-radio label="Is Return Cheque?" value="0"></v-radio>
            </v-radio-group>
            <v-radio-group v-model="is_change_cheque_ask">
              <v-radio label="Is Change Cheque? (YES)" value="1"></v-radio>
              <v-radio label="Is Change Cheque? (NO)" value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" v-show="alert_customize">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message_customize }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col md="12" cols="12" v-if="is_cheque_ask === '1'">
            <v-text-field
              v-model="reason"
              label="Reason of Canceled Cheque"
              dense
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-btn color="primary" @click="cancel_cheque(1)"> Save Changes</v-btn>
          </v-col>
          <v-col md="12" cols="12" v-else-if="is_cheque_ask === '0'">
            <v-btn color="primary" @click="cancel_cheque(0)"> Save Changes</v-btn>
          </v-col>
        </v-card-text>
      </v-card>
      <v-btn color="error" @click="AskCanceledChequeCash = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="EditInfoRequest" persistent max-width="50%">
      <request-information
        :key="key"
        v-on:data="on_save_request_data"
        :category_id="this.selected_request_data.category_id"
        :request_id="this.selected_request_data.request_id"
        :is_regular="voucher_type === 'Cash Voucher' ? 1 : 0"
        :is_pdc="voucher_type === 'Cheque Voucher' ? 1 : 0"
        :is_ftv="voucher_type === 'Fund Transfer Voucher' ? 1 : 0"
        :branch_items="this.branch_items"
        :particulars_items="this.particulars_items"
        :from_bank_items="this.from_bank_items"
        :to_bank_items="this.to_bank_items"
        :is_from_other="1"
        :from_others="this.selected_request_data"
      ></request-information>
      <v-btn color="error" @click="EditInfoRequest = false"> Close</v-btn>
    </v-dialog>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiCheckbook,
    mdiPencil,
    mdiDeleteForever,
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import RequestInformation
    from '@/views/file_interface/create/request/request_components/RequestInformation'

  const initialState = () => {
    return {
      key: 0,
      branch_items: [],
      particulars_items: [],
      from_bank_items: [],
      to_bank_items: [],
      EditInfoRequest: false,
      reason: '',
      payee: '',
      voucher_date: '',
      allocation_of_fund: '',
      alert_customize: false,
      alert_message_customize: '',
      total_amount: 0,
      AskCanceledCheque: false,
      AskCanceledChequeCash: false,
      headers: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Request ID', value: 'tin', sortable: false},
        {text: 'Category', value: 'last_name', sortable: false},
        {text: 'Branch', value: 'last_name', sortable: false},
        {text: 'Particulars', value: 'last_name', sortable: false},
        {text: 'Description', value: 'last_name', sortable: false},
        {text: 'Credit To', value: 'last_name', sortable: false},
        {text: 'Payment For', value: 'last_name', sortable: false},
        {text: 'Amount', value: 'last_name', sortable: false},
        {text: 'Edit', value: 'last_name', sortable: false},
      ],
      data_items: [],
      saving_data: false,
      alert_message: '',
      alert: false,
      voucher_id: '',
      voucher_id_temp: '',
      voucher_items: [],
      voucher_type: '',
      month_of_id: '',
      month_of_items: [],
      can_disapproved: true,
      EditInfo: false,
      is_cheque_ask: '1',
      is_change_cheque_ask: '0',
      selected_voucher_data: {},
      selected_request_data: {},
    }
  }
  export default {
    components: {
      snackBarDialog,
      RequestInformation,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCheckbook,
          mdiPencil,
          mdiDeleteForever,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('request_data', ['initialize_voucher_maintenance', 'initialize_edit_request_data', 'remove_request_from_voucher']),
      ...mapActions('transaction_months', ['list_of_transaction_month']),
      ...mapActions('cash_vouchers', [
        'disapproved_cash_voucher',
        'edit_info_cash_voucher',
        'delete_cash_voucher_from_maintenance',
        'canceled_cheque_no_from_maintenance',
      ]),
      ...mapActions('cheque_vouchers', [
        'disapproved_cheque_voucher',
        'edit_info_cheque_voucher',
        'delete_cheque_voucher_from_maintenance',
        'canceled_cheque_no_from_maintenance_ckv',
      ]),
      ...mapActions('fund_transfer_vouchers', [
        'disapproved_fund_voucher',
        'edit_info_fund_voucher',
        'delete_fund_voucher_from_maintenance',
        'canceled_cheque_no_from_maintenance_ftv',
      ]),
      initialize_data() {
        this.voucher_items = []
        this.data_items = []
        this.selected_voucher_data = this.list_of_transaction_month()
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      cancel_cheque_selected() {
        if (confirm('PROCEED?')) {
          this.alert = false
          if (this.$refs.form.validate()) {
            this.AskCanceledChequeCash = true
            this.is_cheque_ask = '1'
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving_data = false
          }
        }
      },
      edit_request_data(value) {
        this.key++
        if (confirm('PROCEED?')) {
          this.alert = false
          if (this.$refs.form.validate()) {
            this.EditInfoRequest = true
            this.selected_request_data = value
            this.initialize_edit_request_data({
              id: value.request_id,
            })
              .then(response => {
                this.branch_items = response.data[0].branches
                this.particulars_items = response.data[0].particulars
                this.from_bank_items = response.data[0].banks
                this.to_bank_items = response.data[0].banks
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving_data = false
          }
        }
      },
      async on_save_request_data() {
        await this.selected_type_of_voucher()

        this.voucher_id = this.voucher_id_temp
        this.selected_voucher()
        this.EditInfoRequest = false
      },
      change_cheque(value) {
        if (confirm('PROCEED?')) {
          this.alert = false
          if (this.$refs.form.validate()) {
            this.AskCanceledChequeCash = true
            this.selected_request_data = value
            this.is_cheque_ask = '1'
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving_data = false
          }
        }
      },
      cancel_cheque(value) {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var proceed = true
          if (value === 1 && this.reason === '') {
            this.alert_customize = true
            this.alert_message_customize = 'Please Fill up the field/s'
            proceed = false
          }
          if (proceed) {
            if (this.voucher_type === 'Cash Voucher') {
              this.canceled_cheque_no_from_maintenance({
                id: this.voucher_id,
                is_canceled_cheque: value,
                is_change_cheque: this.is_change_cheque_ask === '1' ? 1 : 0,
                reason: this.reason.toUpperCase(),
                employee_id: this.employee_id,
              })
                .then(response => {
                  var color = 'success'
                  if (response.status === 201) {
                    color = 'error'
                  }
                  this.change_snackbar({
                    show: true,
                    color: color,
                    text: response.data,
                  })
                })
                .catch(error => {
                  console.log(error)
                })
            } else if (this.voucher_type === 'Cheque Voucher') {
              this.canceled_cheque_no_from_maintenance_ckv({
                id: this.selected_request_data.id,
                is_canceled_cheque: value,
                is_change_cheque: this.is_change_cheque_ask === '1' ? 1 : 0,
                reason: this.reason.toUpperCase(),
                employee_id: this.employee_id,
              })
                .then(response => {
                  var color = 'success'
                  if (response.status === 201) {
                    color = 'error'
                  }
                  this.change_snackbar({
                    show: true,
                    color: color,
                    text: response.data,
                  })
                })
                .catch(error => {
                  console.log(error)
                })
            } else if (this.voucher_type === 'Fund Transfer Voucher') {
              this.canceled_cheque_no_from_maintenance_ftv({
                id: this.selected_request_data.id,
                is_canceled_cheque: value,
                is_change_cheque: this.is_change_cheque_ask === '1' ? 1 : 0,
                reason: this.reason.toUpperCase(),
                employee_id: this.employee_id,
              })
                .then(response => {
                  var color = 'success'
                  if (response.status === 201) {
                    color = 'error'
                  }
                  this.change_snackbar({
                    show: true,
                    color: color,
                    text: response.data,
                  })
                })
                .catch(error => {
                  console.log(error)
                })
            }
            this.saving_data = false
            this.AskCanceledChequeCash = false
            this.selected_type_of_voucher()
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      edit_info_selected() {
        if (confirm('PROCEED?')) {
          this.EditInfo = true
          this.payee = ''
          this.allocation_of_fund = ''
          this.voucher_date = ''
          var index = this.voucher_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.voucher_id)
          if (index != -1) {
            this.payee = this.voucher_items[index].payee
            this.allocation_of_fund = this.voucher_items[index].allocation_of_fund
            this.voucher_date =
              this.voucher_type === 'Cash Voucher'
                ? this.voucher_items[index].cheque_date
                : this.voucher_items[index].voucher_date
          }
        }
      },
      edit_info() {
        this.alert_customize = false
        if (this.$refs.form2.validate()) {
          if (this.$refs.form.validate()) {
            if (this.voucher_type === 'Fund Transfer Voucher') {
              this.edit_info_fund_voucher({
                id: this.voucher_id,
                voucher_date: this.voucher_date,
                payee: this.payee,
                allocation_of_fund: this.allocation_of_fund,
              })
            } else if (this.voucher_type === 'Cash Voucher') {
              this.edit_info_cash_voucher({
                id: this.voucher_id,
                voucher_date: this.voucher_date,
                payee: this.payee,
                allocation_of_fund: this.allocation_of_fund,
              })
            } else if (this.voucher_type === 'Cheque Voucher') {
              this.edit_info_cheque_voucher({
                id: this.voucher_id,
                voucher_date: this.voucher_date,
                payee: this.payee,
                allocation_of_fund: this.allocation_of_fund,
              })
            }
            this.change_snackbar({
              show: true,
              color: 'success',
              text: 'SUCCESSFULL EDITING THE INFO OF THE VOUCHER',
            })
            this.EditInfo = false
            this.selected_type_of_voucher()
          } else {
            this.alert_customize = true
            this.alert_message_customize = 'Please Select Voucher to Edit First'
          }
        } else {
          this.alert_customize = true
          this.alert_message_customize = 'Please Fill up the field/s'
        }
      },
      async disapprove_voucher() {
        if (confirm('PROCEED?')) {
          this.saving_data = true
          this.alert = false
          if (this.$refs.form.validate()) {
            var message = ''
            var color = 'success'
            if (this.voucher_type === 'Fund Transfer Voucher') {
              await this.disapproved_fund_voucher({
                id: this.voucher_id,
                employee_id: this.employee_id,
              }).then(response => {
                message = response.data
                if (response.status === 201) {
                  color = 'error'
                }
              })
            } else if (this.voucher_type === 'Cash Voucher') {
              await this.disapproved_cash_voucher({
                id: this.voucher_id,
                employee_id: this.employee_id,
              }).then(response => {
                message = response.data
                if (response.status === 201) {
                  color = 'error'
                }
              })
            } else if (this.voucher_type === 'Cheque Voucher') {
              await this.disapproved_cheque_voucher({
                id: this.voucher_id,
                employee_id: this.employee_id,
              }).then(response => {
                message = response.data
                if (response.status === 201) {
                  color = 'error'
                }
              })
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: message,
            })
            this.saving_data = false
            this.selected_type_of_voucher()
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving_data = false
          }
        }
      },
      selected_voucher() {
        this.alert = false
        this.can_disapproved = false
        var index = this.voucher_items
          .map(function (x) {
            return x.id
          })
          .indexOf(this.voucher_id)
        if (index != -1) {
          this.voucher_id_temp = this.voucher_id
          this.selected_voucher_data = this.voucher_items[index]
          this.data_items = this.voucher_items[index].request_data
          var tto = 0

          if (this.voucher_type === 'Cash Voucher') {
            this.data_items.forEach(function (item) {
              tto += parseFloat(item.amount)
            })
          } else {
            this.data_items.forEach(function (item) {
              if (item.pdc_status === 1) {
                tto += parseFloat(item.amount)
              }
            })
          }

          this.total_amount = this.formatPrice(tto)
          var index_month = this.month_of_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.month_of_id)
          if (index_month === 0) {
            this.can_disapproved = this.voucher_items[index].is_approved === 1
          }
        }
      },
      async selected_type_of_voucher() {
        this.alert_customize = false
        this.alert = false
        this.data_items = []
        this.voucher_items = []
        this.voucher_id = ''
        this.selected_voucher_data = {}
        this.selected_request_data = {}
        if (this.voucher_type === 'Fund Transfer Voucher') {
          this.headers = [
            {text: 'ID', value: 'date_of_deposit', sortable: false},
            {text: 'Request ID', value: 'tin', sortable: false},
            {text: 'Category', value: 'last_name', sortable: false},
            {text: 'From Bank', value: 'last_name', sortable: false},
            {text: 'To Bank', value: 'last_name', sortable: false},
            {text: 'Description', value: 'last_name', sortable: false},
            {text: 'Cheque #', value: 'last_name', sortable: false},
            {text: 'Schedule', value: 'last_name', sortable: false},
            {text: 'Amount', value: 'last_name', sortable: false},
            {text: 'Edit', value: 'last_name', sortable: false},
          ]
        } else if (this.voucher_type === 'Cash Voucher') {
          this.headers = [
            {text: 'ID', value: 'date_of_deposit', sortable: false},
            {text: 'Request ID', value: 'tin', sortable: false},
            {text: 'Category', value: 'last_name', sortable: false},
            {text: 'Branch', value: 'last_name', sortable: false},
            {text: 'Particulars', value: 'last_name', sortable: false},
            {text: 'Description', value: 'last_name', sortable: false},
            {text: 'Credit To', value: 'last_name', sortable: false},
            {text: 'Payment For', value: 'last_name', sortable: false},
            {text: 'Amount', value: 'last_name', sortable: false},
            {text: 'Edit', value: 'last_name', sortable: false},
          ]
        } else if (this.voucher_type === 'Cheque Voucher') {
          this.headers = [
            {text: 'ID', value: 'date_of_deposit', sortable: false},
            {text: 'Request ID', value: 'tin', sortable: false},
            {text: 'Category', value: 'last_name', sortable: false},
            {text: 'Branch', value: 'last_name', sortable: false},
            {text: 'Particulars', value: 'last_name', sortable: false},
            {text: 'Description', value: 'last_name', sortable: false},
            {text: 'Credit To', value: 'last_name', sortable: false},
            {text: 'Payment For', value: 'last_name', sortable: false},
            {text: 'Cheque #', value: 'last_name', sortable: false},
            {text: 'Schedule', value: 'last_name', sortable: false},
            {text: 'Amount', value: 'last_name', sortable: false},
            {text: 'Edit', value: 'last_name', sortable: false},
          ]
        }
        await this.initialize_voucher_maintenance({
          month_of_id: this.month_of_id,
          voucher_type: this.voucher_type,
        })
          .then(response => {
            this.voucher_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      delete_voucher_selected() {
        if (confirm('PROCEED?')) {
          this.alert = false
          if (this.$refs.form.validate()) {
            this.AskCanceledCheque = true
            this.is_cheque_ask = '1'
            if (this.voucher_type === 'Fund Transfer Voucher') {
              if (this.selected_voucher_data.from_cheque_no === 0) {
                this.is_cheque_ask = '3'
              }
            } else if (this.voucher_type === 'Cash Voucher') {
              if (this.selected_voucher_data.cheque_no === 0) {
                this.is_cheque_ask = '3'
              }
            } else if (this.voucher_type === 'Cheque Voucher') {
              if (this.selected_voucher_data.from_cheque_no === 0) {
                this.is_cheque_ask = '3'
              }
            }
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving_data = false
          }
        }
      },
      delete_voucher(value) {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var proceed = true
          if (value === 1 && this.reason === '') {
            this.alert_customize = true
            this.alert_message_customize = 'Please Fill up the field/s'
            proceed = false
          }
          if (proceed) {
            if (this.voucher_type === 'Fund Transfer Voucher') {
              this.delete_fund_voucher_from_maintenance({
                id: this.voucher_id,
                is_canceled_cheque: value,
                reason: this.reason.toUpperCase(),
                employee_id: this.employee_id,
              })
                .then(response => {
                  var color = 'success'
                  if (response.status === 201) {
                    color = 'error'
                  }
                  this.change_snackbar({
                    show: true,
                    color: color,
                    text: response.data,
                  })
                })
                .catch(error => {
                  console.log(error)
                })
            } else if (this.voucher_type === 'Cash Voucher') {
              this.delete_cash_voucher_from_maintenance({
                id: this.voucher_id,
                is_canceled_cheque: value,
                reason: this.reason.toUpperCase(),
                employee_id: this.employee_id,
              })
                .then(response => {
                  var color = 'success'
                  if (response.status === 201) {
                    color = 'error'
                  }
                  this.change_snackbar({
                    show: true,
                    color: color,
                    text: response.data,
                  })
                })
                .catch(error => {
                  console.log(error)
                })
            } else if (this.voucher_type === 'Cheque Voucher') {
              this.delete_cheque_voucher_from_maintenance({
                id: this.voucher_id,
                is_canceled_cheque: value,
                reason: this.reason.toUpperCase(),
                employee_id: this.employee_id,
              })
                .then(response => {
                  var color = 'success'
                  if (response.status === 201) {
                    color = 'error'
                  }
                  this.change_snackbar({
                    show: true,
                    color: color,
                    text: response.data,
                  })
                })
                .catch(error => {
                  console.log(error)
                })
            }

            this.saving_data = false
            this.AskCanceledCheque = false
            this.selected_type_of_voucher()
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      remove_from_voucher(item) {
        if (confirm('PROCEED?')) {
          this.remove_request_from_voucher({
            request_id: item.request_id,
            employee_id: this.employee_id,
          })
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.AskCanceledCheque = false
              this.selected_type_of_voucher()
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
  }
</script>
